import swal from 'sweetalert2'
import $ from './JQueryInstance'

export default class Deletable {

    constructor() {
        this.deletables = $('.deletable') || []
    }

    init() {
        this.bindEvents()
    }

    bindEvents() {
        const component = this
        this.deletables.each((i, el) => {

            $(el).on('click', function (e) {
                e.preventDefault()
                e.stopPropagation()
                const name = e.currentTarget.getAttribute('data-name')
                const href = e.currentTarget.getAttribute('href')

                component.showPromptBox({ name, href })
            })

        })
    }

    showPromptBox({ name, href }) {
        swal({
            title:             name,
            text:              'Určite chcete natrvalo odstrániť túto položku?',
            type:              'question',
            showConfirmButton: true,
            showCancelButton:  true,
        }).then(
            () => {
                location.href = href
                // $.post(href, this.onSuccessCallback.bind(this))
            },
            this.onDismiss
        )
    }

    onDismiss() {
        // dismiss param
    }

    // onSuccessCallback(response) {
    //     swal({
    //         title:             'Polozka bola vymazana',
    //         type:              'success',
    //         timer:             2000,
    //         showConfirmButton: false,
    //     }).then(
    //         function () {
    //         }, function (dismiss) {
    //             if (dismiss === 'timer'){
    //                 // todo ???
    //                 window.location.reload()
    //             }
    //         }
    //     )
    // }

}