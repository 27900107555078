import $ from './JQueryInstance'

export default class SortableTable {
    constructor() {
        this.sortedIds    = []
        this.$saveButton  = $('#sort-save')
        this.$sortedTable = $('.sorted_table:eq(0)')

    }

    init() {
        // return false
        this.hideSaveButton()
        this.bindSortEventToTable()
        this.bindSaveButtonClick()
    }

    bindSortEventToTable() {
        this.$sortedTable.sortable({
            containerSelector: 'table',
            itemPath:          '> tbody',
            itemSelector:      'tr',
            handle:            '.sort',
            placeholder:       '<tr class="placeholder" />',
            onDragStart:       this.onDropStartEvent.bind(this),
            onDrop:            this.onDropEvent.bind(this),
        })
    }

    bindSaveButtonClick() {
        const saveUrl = this.$saveButton.attr('data-saveurl')
        this.$saveButton.on('click', () => {
            $.get(saveUrl, { ids: this.sortedIds }, this.hideSaveButton.bind(this))
        })
    }

    onDropStartEvent() {
        this.hideSaveButton()
    }

    onDropEvent() {
        // show button
        this.showSaveButton()

        // reset ids
        this.sortedIds = []

        // bind new ids
        $('tbody tr', this.$sortedTable).each((i, el) => {
            this.sortedIds[i] = $(el).attr('data-itemid')
        })
    }

    showSaveButton() {
        this.$saveButton.show()
    }

    hideSaveButton() {
        this.$saveButton.hide()
    }

    sendUpdateRequest() {

    }
}